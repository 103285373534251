import {useParams} from 'react-router-dom';
import {useExchange, usePaymentCancel, usePaymentFormDetail, usePaymentItem} from '../../../api/exchange';
import * as S from './styles';
import moment from 'moment';
import {useCallback, useEffect, useState} from "react";
import {PaymentDetail} from "../../../types/admin/payment-from";
import {formatNumberWithComma} from "../../../lib/comma";
import {decimal} from "../../../lib/math";
import {PaymentContainer} from "./styles";
import UserInfoInput from "../../payment/common/user-info-input";
import PaymentBx from "../../payment/common/payment-bx";
import {exchangeUsdToPhp} from "../../../lib/exchange";

const categories = ['diving', 'equipment', 'meal', 'add'] as const;
const categoryTitles = {
    diving: '다이빙',
    equipment: '장비',
    meal: '식사',
    add: '추가사항',
};

type Category = (typeof categories)[number];

const AdminPaymentFormDetail = () => {
    const exchange = useExchange();
    const {id} = useParams();
    const paymentDetail = usePaymentFormDetail(id || "");
    const {mutate} = usePaymentItem(paymentDetail?.id);
    const {mutate: mutateCancel} = usePaymentCancel(paymentDetail?.id);


    const [totalKWA, setKWA] = useState<number>(0);
    const [totalUSD, setUSD] = useState<number>(0);
    const [paymentValue, setPaymentValue] = useState<number>(0);

    useEffect(() => {
        if (exchange && paymentDetail) {
            let sum = 0;
            let paymentSum = 0;

            for (const [key, list] of Object.entries(paymentDetail.list)) {
                list.forEach((v: PaymentDetail) => {
                    const t = +v.qty * +v.price;
                    sum += key === 'add' ? exchangeUsdToPhp(t, exchange.php) : t;

                    if (v.isPayment) paymentSum += key === 'add' ? exchangeUsdToPhp(t, exchange.php) : t;
                })
            }

            setUSD(sum);
            setKWA(sum * exchange.usd);
            setPaymentValue(paymentSum * exchange.usd);
        }

    }, [paymentDetail, exchange]);

    const onClickPayment = useCallback((id: string) => {
        mutate({id});
    }, []);

    const onClickCancel = useCallback((id: string) => {
        mutateCancel({id})
    }, []);

    const onClick = useCallback((pg: string) => {
        console.log(pg);
    }, []);

    if (!paymentDetail) return <div>로딩중...</div>;

    return (
        <S.Wrap>
            <S.Inner>
                <S.TitleBx>
                    <h2>정산서 상세</h2>
                </S.TitleBx>

                <S.DetailBx>
                    <S.HeaderInfo>
                        <div className="team-name">
                            <span>팀 이름:</span> {paymentDetail.teamName || 'Default'}
                        </div>
                        <div className="date">
                            <span>작성일:</span> {moment(paymentDetail.createdAt).format('YYYY-MM-DD HH:mm')}
                        </div>
                    </S.HeaderInfo>
                    <S.PaymentContainer>
                        {categories.map((category: Category) => (
                            paymentDetail.list[category]?.length > 0 && (
                                <S.CategoryBx key={category}>
                                    <h3>{categoryTitles[category]}</h3>
                                    <S.TableBx>
                                        <thead>
                                        <tr>
                                            <th>날짜</th>
                                            <th>항목</th>
                                            <th>수량</th>
                                            <th>가격</th>
                                            <th>합계</th>
                                            <th>입금 여부</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {paymentDetail.list[category].map((item: any, index: number) => {
                                            const unit = category === 'add' ? '₱' : '$';
                                            return (
                                                <tr key={index}
                                                    style={{'backgroundColor': item.isPayment === 1 ? 'rgba(255, 255, 0, 0.5)' : 'transparent'}}>
                                                    <td>{item.date}</td>
                                                    <td>{item.articles}</td>
                                                    <td>{item.qty}</td>
                                                    <td>{unit} {item.price}</td>
                                                    <td>{unit} {item.unit}</td>
                                                    <td>
                                                        {item.isPayment === 0 ? '입금 전' : '[입금 완료]'} {item.paymentAt}
                                                        <S.TestButtonBx>
                                                            {
                                                                item.isPayment === 0 ? (
                                                                    <button
                                                                        onClick={() => onClickPayment(item.id)}>
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        onClick={() => onClickCancel(item.id)}>
                                                                    </button>
                                                                )
                                                            }
                                                        </S.TestButtonBx>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </S.TableBx>
                                </S.CategoryBx>
                            )
                        ))}
                    </S.PaymentContainer>

                    <S.TotalBx>
                        <S.TotalBxInner>
                            <div>
                                총 금액 : <span>$ {formatNumberWithComma(+decimal(+totalUSD))}</span>
                            </div>

                            <div>
                                총 금액 : <span>₩ {formatNumberWithComma(+decimal(+totalKWA))}</span>
                            </div>

                            <div>
                                지불 금액: <span>₩ {formatNumberWithComma(+decimal(+paymentValue))}</span>
                            </div>

                            <div>
                                남은 금액: <span>₩ {formatNumberWithComma(+decimal(+totalKWA - +paymentValue))}</span>
                            </div>
                        </S.TotalBxInner>
                    </S.TotalBx>

                    <UserInfoInput/>
                    {/*<PaymentBx/>*/}
                </S.DetailBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default AdminPaymentFormDetail;