import MainContent from "../../components/main";
import Layout from "../../components/layout";

const MainPage = () => {
    return (
        <Layout>
            <MainContent/>
        </Layout>
    )
}

export default MainPage;