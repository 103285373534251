import {getAxios, getAxios2, patchAxios, postAxios} from "./apiLink";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {PaymentDetailList} from "../types/admin/payment-from";

export const useExchange = () => {
    const {data} = useQuery({
        queryFn: () => getAxios('/exchange'),
        queryKey: ['exchange'],
        staleTime: 60 * 60 * 1000
    });

    return data;
}

export const setCreatePaymentForm = async (teamName: string, sendData: any) => {
    const data = await postAxios("/exchange/paymentForm", {
        teamName,
        sendData: sendData
    });
    return data;
}

export const addPaymentFormDetail = async (id: string, formData: {
    category: string;
    date: string;
    articles: string;
    qty: number;
    price: number;
}) => {
    const data = await postAxios(`/exchange/paymentForm/${id}/add`, formData);
    return data;
}

export const usePaymentFormList = () => {
    const {data} = useQuery({
        queryFn: () => getAxios('/exchange/paymentform/list'),
        queryKey: ['paymentFormList'],
        staleTime: 60 * 1000
    });

    return data;
}

export const usePaymentFormDetail = (id: string) => {
    const {data} = useQuery({
        queryFn: () => getAxios2<PaymentDetailList>(`/exchange/paymentForm/${id}`),
        queryKey: ['paymentFormDetail', id],
        staleTime: 60 * 1000
    });

    return data;
}

export const usePaymentItem = (detailId?: number) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (params: any) => patchAxios('/exchange/paymentItem', params),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["paymentFormDetail", String(detailId)]
            });
        }
    });
}

export const usePaymentCancel = (detailId?: number) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (params: any) => patchAxios('/exchange/paymentCancel', params),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["paymentFormDetail", String(detailId)]
            });
        }
    })
}