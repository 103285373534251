import {NewsSendType} from "../types/news";
import axiosCustom, {baseURL} from "./axios";
import axios from "axios";

export const getAxios = async (url: string, params?: any) => {
    const {data} = await axiosCustom.get(url, {
        params,
    });
    return data;
}

export const getAxios2 = async <T>(url: string, params?: any) => {
    const {data} = await axiosCustom.get<T>(url, {
        params,
    });
    return data;
}

export const getAxiosDelete = async (url: string, params?: any) => {
    const {data} = await axiosCustom.delete(url, {
        params
    });

    return data;
}

export const postAxios = async (url: string, sendData: any) => {
    const {data} = await axiosCustom.post(url, sendData);
    return data
}

export const patchAxios = async (url: string, sendData: any) => {
    const {data} = await axiosCustom.patch(url, sendData);
    return data
}

export const postNews = async (url: string, newsData: NewsSendType) => {
    const {data} = await axiosCustom.post(url, {
        newsData
    });

    return data;
}

export const postNotice = async (url: string, sendData: any) => {
    const {data} = await axiosCustom.post(url, sendData);
    return data
}

export const Upload = async (formData: any) => {
    const {data} = await axios.post(baseURL + '/story/upload', formData, {
        withCredentials: true,
        headers: {
            'Content-Type': 'multipart/form-data', // 기본 헤더 설정
        },
    });
    return data;
}