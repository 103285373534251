import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    padding: 100px 0;
    ${media.mobile`
        padding-top: 30px;
    `};
`

export const Inner = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 50px;
    box-sizing: border-box;

    ${media.tablet`
        padding: 0 20px;
    `};
`

export const ContentBx = styled.div`
    
`

export const TitleBx = styled.div`
    h2 {
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 100px;
    }
`