import styled from "styled-components";

export const Wrap = styled.div`
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;
`

export const Inner = styled.div`
    
`

export const TitleBx = styled.div`
    margin-bottom: 30px;
    
    h2 {
        font-size: 24px;
        font-weight: 500;
    }
`

export const DetailBx = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

export const HeaderInfo = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #f8f8f8;
    border-radius: 8px;

    > div {
        > span {
            font-weight: 500;
            margin-right: 10px;
        }
    }

    @media screen and (max-width: 480px) {
        flex-direction: column;
        gap: 10px;
    }
`

export const PaymentContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
    column-gap: 15px;
`

export const CategoryBx = styled.div`
    h3 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
    }
`

export const TableBx = styled.table`
    width: 100%;
    border-collapse: collapse;

    th, td {
        border: 1px solid #ddd;
        padding: 10px;
        text-align: left;

        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(2) {
            width: auto;
        }

        &:nth-child(3) {
            width: 30px;
        }

        &:nth-child(4) {
            width: 70px;
        }

        &:nth-child(5) {
            width: 70px;
        }

        &:nth-child(6) {
            width: 100px;
        }
    }

    th {
        background: #f8f8f8;
    }

    @media screen and (max-width: 768px) {
        display: block;
        overflow-x: auto;
    }
`

export const TotalBx = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;

    span {
        font-weight: 500;
        margin-right: 10px;
    }

    @media screen and (max-width: 480px) {
        flex-direction: column;
        gap: 10px;
    }
`

export const TotalBxInner = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    box-sizing: border-box;
    gap: 5px;

    > div {
        display: flex;
        justify-content: space-between;

        span {
            display: inline-block;
            width: 200px;
            text-align: right;
        }
    }
`

export const TestButtonBx = styled.div`
    display: inline-flex;
    gap: 5px;

    button {
        width: 20px;
        height: 10px;
        border: 1px solid #000000;
        background-color: rgba( 0, 0, 0, 0.5 );
    }
`

export const PaymentListBx = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;

    button {
        width: 200px;
        border-radius: 10px;
        overflow: hidden;
        display: block;
        font-size: 0;
        border: 1px solid #e4e4e4;

        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
`
