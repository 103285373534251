import {PackageItemsType} from "../../../types/main";


export const DataRecommendation: PackageItemsType[] = [
    {
        type: 'RED',
        link: '/recreation/open-water-thrifty',
        src: '/image/img_package_item_00.png',
        price: 300,
        title: '오픈워터 알뜰 패키지(2일)',
        description: '공항픽업 | 숙박2박(2인 1실) | 조식/중식 | 교육 | 라이센스 | 장비렌탈 | 이론강의 | 수영장교육 | 개방수역(4회 다이빙)'
    },
    {
        type: 'RED',
        link: '/recreation/open-water-fun',
        src: '/image/img_package_item_02.jpg',
        price: 450,
        title: '오픈워터(알뜰)+펀(3일)',
        description: '공항픽업 | 숙박3박(2인1실) | 조식/중식 | 교육 | 라이센스 | 장비렌탈 | 이론강의 | 수영장교육 | 개방수역(4회 다이빙) | 펀다이빙(3회)'
    },
]

export const DataPremiumPackage: PackageItemsType[] = [
    {
        type: 'BLUE',
        link: '/recreation/open-water',
        src: '/image/img_package_item_01.jpg',
        price: 450,
        title: '오픈워터 프리미엄 패키지(3일)',
        description: '공항픽업 | 숙박3박(2인 1실) | 조식/중식 | 교육 | 라이센스 | 장비렌탈 | 이론강의 | 수영장교육 | 개방수역(7회 다이빙)'
    },
    {
        type: 'BLUE',
        link: '/recreation/advanced',
        src: '/image/img_package_item_03.jpg',
        price: 350,
        title: '어드밴스드 패키지(2일)',
        description: '공항픽업 | 숙박2박(2인 1실) | 조식/중식 | 교육 | 라이센스 | 장비렌탈 | 이론강의 | 개방수역(6회 다이빙)'
    },
    {
        type: 'PURPLE',
        link: '/recreation/open-water-advanced',
        src: '/image/img_package_item_04.jpg',
        price: 750,
        title: '오픈워터+어드밴스드 패키지(5일)',
        description: '공항픽업 | 숙박5박(2인 1실) | 조식/중식 | 교육 | 라이센스 | 장비렌탈 | 이론강의 | 개방수역(10회 다이빙)'
    },
    {
        type: 'BLUE',
        link: '/recreation/nitrox',
        src: '/image/img_package_item_05.jpg',
        price: 300,
        title: '나이트록스 다이버',
        description: '점심식사 | 장비렌탈\n나이트록스 이론강의 32% 36%(2회 다이빙)'
    },
    {
        type: 'BLUE',
        link: '/recreation/cpr',
        src: '/image/img_package_item_06.jpg',
        price: 300,
        title: '응급처치',
        description: 'FRTI/basic Life\nSupporter/First Aid CPR'
    },
    {
        type: 'BLUE',
        link: '/recreation/rescue',
        src: '/image/img_package_item_07.jpg',
        price: 500,
        title: '레스큐 다이버 패키지(3일)',
        description: '공항픽업 | 숙박3박(2인 1실) | 조식/중식 | 교육 | 라이센스 | 장비렌탈 | 이론강의 | 수영장교육 | 개방수역(다이빙)'
    },
    {
        type: 'BLUE',
        link: '/recreation/master',
        src: '/image/img_package_item_08.jpg',
        price: 1000,
        title: '마스터스쿠버 다이버 패키지(5일)',
        description: '공항픽업 | 숙박5박(2인 1실) | 조식/중식 | 교육 | 라이센스 | 장비렌탈 | 이론강의 | 수영장교육 | 개방수역(다이빙)'
    },
    {
        type: 'BLUE',
        link: '',
        src: '/image/img_package_item_10.jpg',
        price: 250,
        title: '스페셜티 과정',
        description: '이론(1일) | 다이빙(2~3회) | 라이센스포함'
    },
    {
        type: 'BLUE',
        link: '',
        src: '/image/img_package_item_11.jpg',
        price: 200,
        title: '스쿠버 리뷰&메이크업',
        description: '이론(1일) | 다이빙(2회)'
    },
]
