import * as S from "./styles";
import {Link} from "react-router-dom";
import moment from "moment";

const PaymentListItem = ({item}: { item: any }) => {
    return (
        <S.ListItem>
            <Link to={`/admin/payment-form-defail/${item.id}`}>
                <S.ListContent>
                    <dl>
                        <dt>
                            제목 : {item.team_name || 'Default'}
                        </dt>
                        <dd>
                            {moment(item.created_at).format('YYYY-MM-DD HH:mm')}
                        </dd>
                    </dl>
                </S.ListContent>

                <S.AmountBx>
                    <dl>
                        <dt>총 금액:</dt>
                        <dd>${item.total_amount}</dd>
                    </dl>

                    <dl>
                        <dt>지불 금액:</dt>
                        <dd>${item.paid_amount}</dd>
                    </dl>
                </S.AmountBx>
            </Link>
        </S.ListItem>

    )
}

export default PaymentListItem;