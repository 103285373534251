import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    padding: 100px 0;
    ${media.mobile`
        padding-top: 30px;
    `};
`

export const Inner = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 50px;
    box-sizing: border-box;

    ${media.tablet`
        padding: 0 20px;
    `};
`

export const NoticeContainer = styled.div`

`

export const NoticeHeader = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 70px;

    dl {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        dt {
            font-size: 24px;
            font-weight: 700;
        }

        dd {
            font-size: 18px;
            font-weight: 700;
        }
    }

    ${media.tablet`
        margin-bottom: 30px;
    `};
`

export const NoticeBx = styled.div`
    box-sizing: border-box;
    border-top: none;
`

export const NoticeTitle = styled.div`
    border-bottom: 1px solid #cecece;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 15px;

    span {
        &:nth-child(1) {
            font-size: 28px;
            font-weight: 600;
            color: #000000;
        }

        &:nth-child(2) {
            width: 300px;
            font-size: 15px;
            font-weight: 500;
        }
    }
`

export const NoticeContent = styled.div`
    position: relative;
    padding: 30px 10px;
    box-sizing: border-box;
    width: 100%;
`

export const BtnBx = styled.div`
    margin-top: 100px;
    display: flex;
    justify-content: center;
`

export const OrderLinkBx = styled.div`
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    margin-top: 30px;

    &:hover {
        a {
            svg {
                transform: translateY(-3px);
            }
        }
    }

    a {
        box-sizing: border-box;
        font-size: 13px;
        color: #000000;
        font-weight: 700;
        display: inline-flex;
        gap: 5px;
        border-bottom: 1px solid #cecece;
        padding-bottom: 5px;

        svg {
            transition: transform 0.2s;
        }
    }
`
