


export function getComma(str: number | string ){
    return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}


export function formatNumberWithComma(num: number | string): string {
    return num.toLocaleString("en-US");
}