import * as S from "./styles";
import PaymentForm from "./payment-form";
import {DivingData, EquipmentData, MealData, AddData} from "./data";
import PaymentTotal from "./payment-total";
import PaymentUnit from "./payment-unit";
import BtnDefault from "../../common/btn-default";
import {usePaymentFormContext} from "../../../pages/admin/AdminPaymentFormPage";

const AdminPaymentForm = () => {
    const {onClickCreate, teamName, setTeamName} = usePaymentFormContext();

    return (
        <S.Wrap>
            <S.Inner>
                <S.TeamNameBx>
                    <h2>팀 이름</h2>
                    <input
                        type="text"
                        value={teamName}
                        onChange={(e) => setTeamName(e.target.value)}
                    />
                </S.TeamNameBx>

                <S.TitleBx>정산서</S.TitleBx>

                <S.FormBx>
                    <PaymentForm paymentFormData={DivingData}/>
                    <PaymentForm paymentFormData={EquipmentData}/>
                </S.FormBx>
                <S.FormBx>
                    <PaymentForm paymentFormData={MealData}/>
                    <PaymentForm paymentFormData={AddData}/>
                </S.FormBx>

                <S.TotalBx>
                    <PaymentTotal/>
                    <PaymentUnit/>
                </S.TotalBx>

                <S.BtnBx>
                    <BtnDefault title={'정산서 생성하기'} onClick={onClickCreate}></BtnDefault>
                </S.BtnBx>

            </S.Inner>
        </S.Wrap>
    )
}

export default AdminPaymentForm;