import styled from "styled-components";

export const ListItem = styled.div`
    a {
        &:hover{
            box-shadow: 2px 2px 5px rgba( 0, 0, 0, 0.5 );
        }
        width: 100%;
        height: 150px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid #eeeeee;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        transition: box-shadow 0.1s ease-out;
    }
`

export const ListContent = styled.div`
    dl {
        display: flex;
        justify-content: space-between;
        align-items: center;

        dt {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
        }

        dd {
            font-size: 14px;
            font-weight: 500;
            color: #000000;
        }
    }
`

export const AmountBx = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    dl {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        dt {
            font-size: 14px;
            font-weight: 600;
            color: #000000;
        }

        dd {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
            width: 100px;
            text-align: right;
        }
    }
`
