import * as S from "./styles";
import {usePaymentFormContext} from "../../../../pages/admin/AdminPaymentFormPage";
import {formatNumberWithComma} from "../../../../lib/comma";
import {decimal} from "../../../../lib/math";
import moment from "moment";

const PaymentTotal = () => {
    const {totalUSD, exchange} = usePaymentFormContext();
    if (!exchange) return null;

    return (
        <S.Inner>
            <S.TableBx>
                <tbody>
                <tr>
                    <th colSpan={3}>총액</th>
                </tr>

                <tr>
                    <td>A+B+C+D</td>
                    <td style={{'textAlign': 'right', 'backgroundColor': 'yellow'}}>
                        <span>$</span>
                        {
                            formatNumberWithComma(+decimal(+totalUSD))
                        }
                    </td>
                </tr>

                <tr>
                    <td>
                        <dl>
                            <dt>환율 정보( 기준 : $1 )</dt>
                            <dd>업데이트 : {moment(exchange.updatedAt).format('YYYY/MM/DD')}</dd>
                        </dl>
                    </td>
                    <td colSpan={2}>
                        <p>원화 : {exchange.usd}</p>
                        <p>페소 : {exchange.php}</p>
                    </td>
                </tr>
                </tbody>
            </S.TableBx>
        </S.Inner>
    )
}

export default PaymentTotal;