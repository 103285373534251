import * as S from "./styles";
import {useCallback, useEffect, useRef, useState} from "react";
import {GetCalculatorData} from "../../api/calculator";
import {useParams} from "react-router-dom";
import {getComma} from "../../lib/comma";
import BtnDefault from "../common/btn-default";
import moment from "moment";

function getAddPrice(arr: any) {
    return arr.reduce((a: number, b: any) => !isNaN(b.price) ? a + +b.price : a, 0);
}

const CompleteContentBx = () => {
    const {num} = useParams();
    const [data, setData] = useState<any>();
    const getData = async () => {
        const data = await GetCalculatorData(Number(num));
        setData(data);
    }

    useEffect(() => {
        getData();
    }, []);

    const textAreaRef = useRef(null);
    const onClickClipboard = useCallback(() => {
        if (window) {
            if (textAreaRef.current) {
                const textArea = textAreaRef.current as HTMLTextAreaElement;

                if (navigator.clipboard) {
                    window.navigator.clipboard.writeText(textArea.value);
                    console.log('clipboard');
                } else {
                    textArea.select();
                    document.execCommand('copy');
                    console.log('copy');
                }
            }

            alert('공유 링크가 복사 되었습니다.');
        }
    }, []);

    if (!data) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <S.HeaderBx>
                    <h2>- {data.process.type} -</h2>
                    <dl>
                        <dt>{data.info.userName}</dt>
                        <dd>{moment(data.info.date).format("YYYY/MM/DD")}</dd>
                    </dl>
                </S.HeaderBx>
                <S.Container>

                    <S.ContentBx>
                        {
                            data.funDivingList && <S.ContentListBx>
                                <S.ContentTitleBx>
                                    <dt>{data.funDivingList.process.name}</dt>
                                    <dd>
                                        ${data.funDivingList.process.price}
                                        <span> + ${getComma(getAddPrice(data.funDivingList.processList))}</span>
                                    </dd>
                                </S.ContentTitleBx>

                                <S.ContentList>
                                    {
                                        data.funDivingList.processList.map((item: any, idx: number) => (
                                            <li key={`fun-diving-list-${idx}`}>
                                                <dl>
                                                    <dt>{item.name}</dt>
                                                    <dd>${item.price}</dd>
                                                </dl>
                                            </li>
                                        ))
                                    }
                                </S.ContentList>
                            </S.ContentListBx>
                        }
                        {
                            data.technicalList && <S.ContentListBx>
                                <S.ContentTitleBx>
                                    <dt>{data.technicalList.process.name}</dt>
                                    <dd>
                                        <span>${getComma(getAddPrice(data.technicalList.processList))}</span>
                                    </dd>
                                </S.ContentTitleBx>

                                <S.ContentList>
                                    {
                                        data.technicalList.processList.map((item: any, idx: number) => (
                                            <li key={`fun-diving-list-${idx}`}>
                                                <dl>
                                                    <dt>{item.name}</dt>
                                                    <dd>${item.price}</dd>
                                                </dl>
                                            </li>
                                        ))
                                    }
                                </S.ContentList>
                            </S.ContentListBx>
                        }

                        {
                            data.technicalList && data.technicalList.options &&
                            <S.ContentListBx>
                                <S.ContentTitleBx>
                                    <dt>추가사항</dt>
                                    <dd>
                                        <span>${getComma(getAddPrice(data.technicalList.options))}</span>
                                    </dd>
                                </S.ContentTitleBx>

                                <S.ContentList>
                                    {
                                        data.technicalList.options.map((item: any, idx: number) => (
                                            <li key={`technical-options-${idx}`}>
                                                <dl>
                                                    <dt>{item.name}</dt>
                                                    <dd>{item.price}</dd>
                                                </dl>
                                            </li>
                                        ))
                                    }
                                </S.ContentList>
                            </S.ContentListBx>
                        }

                        <S.ContentListBx>
                            <S.ContentTitleBx>
                                <dt>날짜</dt>
                                {
                                    data.schedule.day &&
                                    <dd>{data.schedule.day}일</dd>
                                }
                            </S.ContentTitleBx>

                            <S.ContentList>
                                {
                                    data.schedule && <>
                                        {
                                            data.schedule.startDay && <li>
                                                <dl>
                                                    <dt>시작 날짜</dt>
                                                    <dd>{data.schedule.startDay}</dd>
                                                </dl>
                                            </li>
                                        }
                                        {
                                            data.schedule.endDay && <li>
                                                <dl>
                                                    <dt>마지막 날짜</dt>
                                                    <dd>{data.schedule.endDay}</dd>
                                                </dl>
                                            </li>
                                        }
                                    </>
                                }
                            </S.ContentList>
                        </S.ContentListBx>

                        <S.ContentListBx>
                            <S.ContentTitleBx>
                                <dt>참가 인원</dt>
                                <dd>
                                    {data.gender.man.member + data.gender.woman.member}명
                                </dd>
                            </S.ContentTitleBx>

                            <S.ContentList>
                                <li>
                                    <dl>
                                        <dt>남자</dt>
                                        <dd>{data.gender.man.member}명</dd>
                                    </dl>
                                </li>
                                <li>
                                    <dl>
                                        <dt>여자</dt>
                                        <dd>{data.gender.woman.member}명</dd>
                                    </dl>
                                </li>
                            </S.ContentList>
                        </S.ContentListBx>

                        <S.ContentListBx>
                            <S.ContentTitleBx>
                                <dt>Rental</dt>
                            </S.ContentTitleBx>

                            {
                                data.rental.man.length > 1 || data.rental.woman.length > 1 ? (
                                    <>
                                        <S.ContentListGrid>
                                            {
                                                data.rental.man.map((list: any, idx: number) => (
                                                    <S.ContentList key={`rental-man-${idx}`}>
                                                        <S.ContentListTitleBx>
                                                            <dt>남자({idx + 1})</dt>
                                                            <dd>
                                                                ${getComma(getAddPrice(list))}
                                                            </dd>
                                                        </S.ContentListTitleBx>
                                                        {
                                                            list.map((item: any, sIdx: number) => (
                                                                <li key={`rental-man-list-${idx}-${sIdx}`}>
                                                                    <dl>
                                                                        <dt>{item.name}</dt>
                                                                        <dd>${item.price}</dd>
                                                                    </dl>
                                                                </li>
                                                            ))
                                                        }
                                                    </S.ContentList>
                                                ))
                                            }
                                        </S.ContentListGrid>
                                        <S.ContentListGrid>
                                            {
                                                data.rental.woman.map((list: any, idx: number) => (
                                                    <S.ContentList key={`rental-woman-${idx}`}>
                                                        <S.ContentListTitleBx>
                                                            <dt>여자({idx + 1})</dt>
                                                            <dd>
                                                                ${getComma(getAddPrice(list))}
                                                            </dd>
                                                        </S.ContentListTitleBx>
                                                        {
                                                            list.map((item: any, sIdx: number) => (
                                                                <li key={`rental-woman-list-${idx}-${sIdx}`}>
                                                                    <dl>
                                                                        <dt>{item.name}</dt>
                                                                        <dd>${item.price}</dd>
                                                                    </dl>
                                                                </li>
                                                            ))
                                                        }
                                                    </S.ContentList>
                                                ))
                                            }
                                        </S.ContentListGrid>
                                    </>
                                ) : (
                                    <S.Empty>
                                        <span>신청 사항 없음</span>
                                    </S.Empty>
                                )
                            }
                        </S.ContentListBx>

                        <S.ContentListBx>
                            <S.ContentTitleBx>
                                <dt>가고 싶은 곳?</dt>
                                {
                                    data.more && data.more.length > 1 && <dd>${getComma(getAddPrice(data.more))}</dd>
                                }
                            </S.ContentTitleBx>

                            {
                                data.more && data.more.length > 1 ? (
                                    <>
                                        <S.ContentList>
                                            {
                                                data.more.map((item: any, idx: number) => (
                                                    <li key={`more-${idx}`}>
                                                        <dl>
                                                            <dt>{item.name}</dt>
                                                            <dd>${item.price}</dd>
                                                        </dl>
                                                    </li>
                                                ))
                                            }
                                        </S.ContentList>
                                        <S.MessageBx>* 당일 기상상황 고려하여 진행이 불가능할 수 있습니다.</S.MessageBx>
                                    </>
                                ) : (
                                    <S.Empty>
                                        <span>신청 사항 없음</span>
                                    </S.Empty>
                                )
                            }

                        </S.ContentListBx>

                        <S.ContentListBx>
                            <S.ContentTitleBx>
                                <dt>문의 사항</dt>
                            </S.ContentTitleBx>
                            {
                                <S.ContentList>
                                    <li>
                                        <dl>
                                            <dt>
                                                <S.NoticeBx>
                                                    {data.info.message}
                                                </S.NoticeBx>
                                            </dt>
                                        </dl>
                                    </li>
                                </S.ContentList>
                            }
                        </S.ContentListBx>
                    </S.ContentBx>

                    <S.TotalPrice>
                        <dl>
                            <dt>총 금액</dt>
                            <dd>${data.total && getComma(data.total.totalPrice)}</dd>
                        </dl>
                    </S.TotalPrice>

                    <S.ImgBx>
                        <img src="/image/logo.svg" alt=""/>
                    </S.ImgBx>
                </S.Container>

                <S.MessageListBx>
                    {
                        data.process?.type === 'TECHNICAL' ? (
                            <ul>
                                <li>교육 기간 내에 모든 스킬을 숙달할 수 없는 경우도 있습니다.</li>
                                <li>그럴 경우에 메이크업 교육을 진행 할 수 있습니다.</li>
                                <li>메이크업의 경우 본인이 요청한 경우에는 비용이 부과됩니다.</li>
                                <li>하지만, 비정기적으로 열리는 메이크업 클래스의 경우 추가 비용 없이 무료로 참석 가능합니다.</li>
                                <li>비용 계산 기능은 예상치이며 실제 서비스 진행 과정에서 추가되거나 빠지는 서비스가 있을 수 있음으로 100% 동일한 금액으로 진행 되지
                                    않습니다.
                                </li>
                                <li>자세한 내용은 문의하기를 눌러주세요.</li>
                            </ul>
                        ) : (
                            <ul>
                                <li>비용 계산 기능은 예상치이며 실제 서비스 진행 과정에서 추가되거나 빠지는 서비스가 있을 수 있음으로 100% 동일한 금액으로 진행 되지
                                    않습니다.
                                </li>
                                <li>자세한 내용은 문의하기를 눌러주세요.</li>
                                <li>강사를 등록하시면 다양한 혜택을 확인 하실 수 있습니다.</li>
                            </ul>
                        )
                    }
                </S.MessageListBx>

                <S.ShareBx>
                    <BtnDefault title={'확인'} link={'/notice/list'}/>
                    {/*<BtnDefault title={'링크 공유하기'} onClick={onClickClipboard}/>*/}
                    {/*<BtnDefault link={'/expense/calculator'} title={'다시 계획 짜기'}></BtnDefault>*/}
                    <div>
                        <S.TextAreaBx ref={textAreaRef} value={window.location.href} readOnly/>
                    </div>
                </S.ShareBx>

            </S.Inner>
        </S.Wrap>
    );
}

export default CompleteContentBx;