import styled from "styled-components";

export const Wrap = styled.div`
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
`

export const Inner = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
`

export const TeamNameBx = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
        justify-content: center;
    }

    h2 {
        font-size: 16px;
    }

    input {
        width: 200px;
        @media screen and (max-width: 480px) {
            width: 100%;
        }
    }
`

export const TitleBx = styled.div`
    border: 3px solid #000000;
    width: 100%;
    padding: 30px 0;
    text-align: center;
    font-size: 42px;
    font-weight: 500;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
        font-size: 32px;
        padding: 20px 0;
    }

    @media screen and (max-width: 480px) {
        font-size: 24px;
        padding: 15px 0;
    }
`

export const FormBx = styled.div`
    display: flex;
    justify-content: center;
    gap: 30px;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
    }
`

export const TotalBx = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 30px;
    margin: 30px 0;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    @media screen and (max-width: 480px) {
        gap: 15px;
        margin: 20px 0;
    }
`

export const BtnBx = styled.div`
    text-align: center;
    margin-top: 100px;
`