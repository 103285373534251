import Layout from "../../layout";
import {Outlet} from "react-router-dom";
import {Link, useLocation} from "react-router-dom";
import * as S from "./styles";
import {AdminMenuBx} from "./styles";

export const AdminLayout = () => {
    const location = useLocation();

    const menuList = [
        {
            title: '어드민',
            path: '/admin'
        },{
            title: '정산서 생성',
            path: '/admin/payment-form'
        },
        {
            title: '정산서 목록',
            path: '/admin/payment-form-list'
        },
        {
            title: '환율 설정',
            path: '/admin/exchange'
        }
    ];

    return (
        <Layout>
            <S.Wrap>
                <S.Inner>
                    <S.TitleBx>
                        <h1>관리자 페이지</h1>
                    </S.TitleBx>

                    <AdminMenuBx>
                        <ul>
                            {
                                menuList.map((menu) => (
                                    <li key={menu.path}>
                                        <Link 
                                            to={menu.path}
                                            className={location.pathname === menu.path ? 'active' : ''}
                                        >
                                            {menu.title}
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </AdminMenuBx>

                    <S.LayoutBx>
                        <Outlet/>
                    </S.LayoutBx>
                </S.Inner>
            </S.Wrap>
        </Layout>
    )
}

export default AdminLayout;