import styled from "styled-components";

export const Wrap = styled.div`
    
`

export const Inner = styled.div`
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 20px;
    box-sizing: border-box;
`

export const TitleBx = styled.div`
    h1{
        font-size: 32px;
        font-weight: 700;
        
        margin-bottom: 50px;
    }
`

export const AdminMenuBx = styled.div`
    ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        li {
            a {
                font-size: 18px;
                font-weight: 700;
                color: #000000;
            }
        }
    }
`

export const LayoutBx = styled.div`
    margin-top: 50px;
`