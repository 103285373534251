import styled from "styled-components";

export const Inner = styled.div`
    width: 100%;
`

export const TableBx = styled.table`
    border-collapse: collapse;
    width: 100%;

    tr {
        border: 1px solid #000000;

        &:nth-child(2) {
            td {
                &:nth-child(3) {
                    text-align: center;
                }
            }
        }

        th {
            text-align: center;
            font-weight: 500;
            padding: 10px 0;
            box-sizing: border-box;
        }

        td {
            text-align: center;
            font-weight: 500;
            padding: 10px;
            box-sizing: border-box;
            position: relative;

            span {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
            }

            &:nth-child(1) {
                width: 150px;
            }

            &:nth-child(2) {
                width: 150px;
            }

            &:nth-child(3) {
                width: auto;
            }
        }
    }

    td {
        border: 1px solid #000000;
    }
`