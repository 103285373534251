import * as S from "./styles";
import PackageList from "../package-list";
import CalendarBx from "../calendar-bx";
import {useEffect, useState} from "react";
import Personnel from "../personnel";
import RentalComponent from "../rental-component";
import MoreComponent from "../more-component";
import MessageComponent from "../message-component";
import BtnDefault from "../../../../../common/btn-default";
import {useCalculatorContext} from "../../index";
import {GetProcess} from "../../../../../../api/calculator";
import {CalculatorListType} from "../../../../../../types/calculator";

const FunDivingBx = () => {
    const [fundivingData, setFundivingData] = useState<CalculatorListType[]>();

    const getData = async () => {
        const result = await GetProcess(2);
        setFundivingData(result.list);
    }

    useEffect(() => {
        getData();
    }, []);

    const {complete, data} = useCalculatorContext();

    const [man, setMan] = useState<number>(0);
    const [woman, setWoman] = useState<number>(0);

    if (!fundivingData) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <S.ContentBx $isView={true}>
                    <PackageList
                        key={'fun-diving-list'}
                        calculatorList={fundivingData}
                        packageName={'funDivingList'}
                    />
                </S.ContentBx>

                <S.ContentBx $isView={!!data.funDivingList}>
                    <h3>2. 날짜 선택하기</h3>
                    {
                        data.funDivingList && <CalendarBx type="FUNDIVING"/>
                    }
                </S.ContentBx>

                <S.ContentBx $isView={!!data.schedule}>
                    <h3>3. 참가 인원</h3>
                    {
                        data.schedule && <Personnel
                            setMan={setMan}
                            setWoman={setWoman}
                        />
                    }
                </S.ContentBx>

                <S.ContentBx $isView={(man > 0 || woman > 0)}>
                    <h3>4. 렌탈 선택</h3>
                    {
                        (man > 0 || woman > 0) && <RentalComponent man={man} woman={woman}/>
                    }
                </S.ContentBx>

                <S.ContentBx $isView={(man > 0 || woman > 0)}>
                    <h3>5. 가고 싶은 곳 있어?</h3>
                    {
                        (man > 0 || woman > 0) && <MoreComponent/>
                    }
                </S.ContentBx>

                {/*<S.ContentBx $isView={(man > 0 || woman > 0)}>*/}
                {/*    <h3>6. 추가 입력</h3>*/}
                {/*    {*/}
                {/*        (man > 0 || woman > 0) && <MessageComponent/>*/}
                {/*    }*/}
                {/*</S.ContentBx>*/}


                <S.ContentBx $isView={(man > 0 || woman > 0)}>
                    <BtnDefault
                        title={'최종 완료'}
                        onClick={complete}
                        disabled={!(man > 0 || woman > 0)}
                    />
                </S.ContentBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default FunDivingBx;