import {Link} from "react-router-dom";
import * as S from "./styles";
import {FooterInfoBx} from "./styles";

const Footer = () => {
    return(
        <S.Wrap>
            <S.Inner>
                <S.MenuBx>
                    <h3>TEAM MAX DIVE RESORT</h3>
                    <ul>
                        <li><Link to={"/team-max/about"}>팀맥스 소개</Link></li>
                        <li><Link to={"/team-max/contact"}>찾아오시는 길</Link></li>
                        <li><Link to={"/price"}>가격안내</Link></li>
                        <li><Link to={"/"}>공지사항</Link></li>
                    </ul>
                </S.MenuBx>
                <S.InfoBx>
                    <S.FooterInfo>
                        <S.FooterInfoBx>
                            <dl>
                                <dt>법인명 :</dt>
                                <dd>팀맥스코리아</dd>
                            </dl>
                            <dl>
                                <dt>대표 :</dt>
                                <dd>이우권</dd>
                            </dl>
                            <dl>
                                <dt>주소 :</dt>
                                <dd>서울특별시 강동구 성안로 43, 지하 1층</dd>
                            </dl>
                            <dl>
                                <dt>사업자등록번호 :</dt>
                                <dd>316-12-02100</dd>
                            </dl>
                            <dl>
                                <dt>통신판매업신고번호 :</dt>
                                <dd>제2025-서울강동-0255</dd>
                            </dl>
                            <dl>
                                <dt>관광사업자 등록번호 :</dt>
                                <dd>제2024-000008호</dd>
                            </dl>
                        </S.FooterInfoBx>
                        <S.FooterInfoBx>
                            <dl>
                                <dt>등록관청 :</dt>
                                <dd>서울특별시 강동구청</dd>
                            </dl>
                            <dl>
                                <dt>개인정보 보호책임자 :</dt>
                                <dd>이광규</dd>
                            </dl>
                            <dl>
                                <dt>보증보험 :</dt>
                                <dd>제100-000-202500089697호</dd>
                            </dl>
                            <dl>
                                <dt>E-mail :</dt>
                                <dd>
                                    <a href="mailto:teammaxdivetopteam@gmail.com">teammaxdivetopteam@gmail.com</a>
                                </dd>
                            </dl>
                            <dl>
                                <dt>문의 :</dt>
                                <dd>010-5629-4266</dd>
                            </dl>
                        </S.FooterInfoBx>
                    </S.FooterInfo>
                </S.InfoBx>
                <S.Copyright>Copyright © TEAM MAX OCEAN. All Rights Reserved.</S.Copyright>
            </S.Inner>
        </S.Wrap>
    )
}

export default Footer;