import {useStoryList} from "../../../api/useStory";
import Board from "../../common/board";
import * as S from "./styles";
import React from "react";

const StoryList = () => {
    const list = useStoryList();

    if (!list) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <S.StoryHeader>
                    <dl>
                        <dt>TEAM MAX STORY</dt>
                    </dl>
                </S.StoryHeader>
                <Board list={list} link={'/story/content/'} onDelete={() => console.log(1)}></Board>
            </S.Inner>
        </S.Wrap>
    )
}

export default StoryList;