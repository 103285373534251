import * as S from "./styles";
import {usePaymentFormList} from "../../../api/exchange";
import PaymentListItem from "./payment-list-item";

const AdminPaymentFormList = () => {
    const paymentFormList = usePaymentFormList();

    return (
        <S.Wrap>
            <S.Inner>
                <S.TitleBx>
                    <h2>정산서 목록</h2>
                </S.TitleBx>

                <S.ListBx>
                    {paymentFormList && paymentFormList.length > 0 ? (
                        paymentFormList.map((item: any) => (
                            <PaymentListItem key={`payment-list-item-${item.id}`} item={item}/>
                        ))
                    ) : (
                        <S.Empty>생성된 정산서가 없습니다.</S.Empty>
                    )}
                </S.ListBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default AdminPaymentFormList;