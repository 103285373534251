import styled from "styled-components";
import {Link} from "react-router-dom";
import {ReactNode} from "react";

const BtnWrap = styled.div<{ $disabled: boolean }>`
    display: inline-block;

    a, button {
        display: inline-block;
        padding: 8px 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        border-radius: 8px;
        overflow: hidden;
        transition: background-color 0.15s;
        position: relative;
        pointer-events: ${p => p.$disabled ? 'none' : 'initial'};
        min-width: 100px;
        text-align: center;

        span {
            position: relative;
            z-index: 10;
        }

        &:hover {
            i {
                &:last-child {
                    width: 100%;
                }
            }
        }

        i {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: ${p => p.$disabled ? '#aaaaaa' : '#C6151B'};
            border-radius: 8px;
            overflow: hidden;

            &:last-child {
                transition: width 0.25s ease-out;
                width: 0;
                background-color: #000000;
            }
        }
    }
`

interface Props {
    title: string;
    link?: string;
    onClick?: () => void;
    disabled?: boolean;
}

const BtnDefault = ({title, link, onClick, disabled = false}: Props) => {
    return (
        <BtnWrap $disabled={disabled}>
            {
                link ? (
                    <Link to={link}>
                        <span>{title}</span>
                        <i></i>
                        <i></i>
                    </Link>
                ) : (
                    <button onClick={onClick}>
                        <span>{title}</span>
                        <i></i>
                        <i></i>
                    </button>
                )
            }
        </BtnWrap>
    )
}

export default BtnDefault;