import AdminPaymentFormList from "../../../components/admin/admin-payment-form-list";

const AdminPaymentFormListPage = () => {

    return (
        <>
            <AdminPaymentFormList/>
        </>
    )
}

export default AdminPaymentFormListPage;
