import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    background-color: #0F0F10;

    ${media.tablet`
        position: relative;
    `};
`

export const Inner = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 0;

    ${media.tablet`
        padding: 80px 20px;
        box-sizing: border-box;
    `};

    ${media.mobile`
        padding: 42px 30px 80px;
    `}
`

export const MenuBx = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    h3 {
        font-family: Poppins, serif;
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        color: #ffffff;
    }

    ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 27px;

        li {
            position: relative;
            padding-right: 27px;

            &:last-child {
                padding-right: 0;

                &:after {
                    display: none;
                }
            }

            a {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: #ffffff;
            }

            &:after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 12px;
                background-color: #333333;
                position: absolute;
                right: 0;
                top: 4px;
            }
        }
    }

    ${media.tablet`
        flex-direction: column;
        margin-bottom: 30px;
        h3{
            margin-bottom: 20px;
        }
    `};

    ${media.mobile`
        margin-bottom: 29px;
        align-items: flex-start;
        h3{
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 0px;
        }
        ul{
            display: none;
        }
    `};
`;

export const InfoBx = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    ${media.tablet`
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `};

    ${media.mobile`
        align-items: flex-start;
    `};
`

export const FooterInfo = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    ${media.tablet`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 0px;
        gap: 5px;
    `};
`

export const FooterInfoBx = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;

    dl {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;

        dt {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #ffffff;
        }

        dd {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #ffffff;

            a {
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: #ffffff;
                text-decoration: underline;
            }
        }
    }

    ${media.tablet`
        width: 100%;
        align-items: center;
        justify-content: center;
    `};

    ${media.mobile`
        align-items: flex-start;
        dl{
            align-items: flex-start;
            dt{ 
                flex-shrink: 0; 
               }
            dd{}
        }
    `};
`

export const Copyright = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    margin-top: 20px;
    
    ${ media.tablet`
        text-align: center;
    ` };

    ${media.mobile`
        text-align: left;
    `};
`