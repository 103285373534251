import * as S from "./styles";
import {usePaymentFormContext} from "../../../../pages/admin/AdminPaymentFormPage";
import {formatNumberWithComma} from "../../../../lib/comma";
import {decimal} from "../../../../lib/math";

const PaymentUnit = () => {
    const {totalUSD, totalKWA, exchange} = usePaymentFormContext();
    if (!exchange) return null;

    return (
        <S.Inner>
            <S.TableBx>
                <tbody>
                <tr>
                    <td>달러</td>
                    <td style={{'textAlign': 'right'}}>
                        <span>$</span>
                        {
                            formatNumberWithComma(+decimal(+totalUSD))
                        }
                    </td>
                </tr>
                <tr>
                    <td>한화</td>
                    <td style={{'textAlign': 'right'}}>
                        <span>₩</span>
                        {
                            formatNumberWithComma(+decimal(+totalKWA))
                        }
                    </td>
                </tr>
                <tr>
                    <td style={{'width': '100%', 'textAlign': 'center'}} colSpan={2}>카카오뱅크 이우권 3333170414339</td>
                </tr>
                </tbody>
            </S.TableBx>
        </S.Inner>
    )
}

export default PaymentUnit;