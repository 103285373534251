import Layout from "../../../components/layout";
import StoryList from "../../../components/story/story-list";
const StoryListPage = () => {
    return(
        <Layout>
            <StoryList />
        </Layout>
    )
}

export default StoryListPage;