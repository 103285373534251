import styled from "styled-components";
import media from "../../lib/media";

export const Wrap = styled.div`

`

export const Inner = styled.div`

`

export const ContentBx = styled.div`

`

export const Container = styled.div`
    min-height: calc(100dvh);
    box-sizing: border-box;
    padding-bottom: 312px;
    position: relative;

    ${media.tablet`
        min-height: auto;
        padding-bottom: 0px;
    `};
`